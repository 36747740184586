import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import theme from '../theme';
import { withTheme } from 'styled-components';
import GlobalStyle from '../theme/globalstyle';
import Footer from '../components/footer';
import Cursor from '../components/cursor';

const Layout = (props) => {

  const { children, location } = props;

  return (
    <>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <Cursor />
        {children}
        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTheme(Layout);
