import React from 'react';
import styled from 'styled-components';

const Div = styled.footer`
  background-color: ${props => props.theme.darkgrey};
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 12px;
  }
  a {
    color: #999;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: #C7C5C1;
    }
  }
`

const Footer = () => {
  return (
    <Div>    <p><a href="mailto:hello@pierre.io">hello@pierre.io</a> &copy; Pierre Nel. All Rights Reserved.</p></Div>
  )
}

export default Footer;
